<template>
  <div>
    <div class="mt-1">
      <!-- form start -->
      <validation-observer ref="addForm">
        <b-form @submit.prevent>
          <b-card class="block-text" title="">
            <div class="border-bottom pb-1 mb-2">
              <span class="h4"
                >DETAILS DU MANIFESTE DE CARTE D'IMPORTATEUR</span
              >
              <!-- <b-button
								size="sm"
								variant="primary"
								style="margin-left:52%;"
							>
								<b-icon-printer-fill></b-icon-printer-fill>
								<span style="margin-left:10px;">ENREGISTRER</span>
							</b-button> -->
            </div>

            <div class="row">
              <b-form-group class="col mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="importateur"
                  rules="required"
                >
                  <label for="marque">Importateur</label>
                  <b-form-select
                    id="marque"
                    v-model="addManifesteForm.importateur"
                    :options="importateursList"
                    value-field="id"
                    text-field="name"
                    @click="resetInputImportateur"
                    :state="errors.length > 0 ? false : null"
                    @change="checkAssocie"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsImportateur }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col mr-2">
                <validation-provider
                  #default="{ errors }"
                  name="societe"
                  rules="required"
                >
                  <label for="societe">Société</label>
                  <b-form-select
                    :disabled="societesList.length == 1"
                    id="societe"
                    v-model="addManifesteForm.societe"
                    :options="societesList"
                    value-field="id"
                    text-field="name"
                    @click="resetInputSociete"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsSociete }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col pr-0">
                <validation-provider
                    #default="{ errors }"
                    name="navire"
                    rules="required"
                >
                  <label for="navire">Navire</label>
                  <combo-box-component
                      id="navire"
                      :dataSource="naviresList"
                      v-model="addManifesteForm.navire"
                      @click="resetInputNavire"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  <small class="text-danger">{{
                      errors[0] ? errors[0] : errorsNavire
                    }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-card>
          <b-alert
            class="mt-2"
            :show="showManifestStockInitial"
            variant="primary"
          >
            <div class="alert-body d-flex flex justify-space-between">
              <span class="mr-auto"
                >Ceci est un manifeste de stock initial
              </span>
              <b-form-checkbox
                class="ml-auto"
                variant="warning"
                switch
                inline
                @change="checkIfStockinitial()"
                v-model="enregistrement_stock_initial"
              >
              </b-form-checkbox>
            </div>
          </b-alert>


          <b-card class="mt-1 block-text" title="">
            <div class="border-bottom pb-1 mb-2">
              <span class="h4">VEHICULE DU MANIFESTE</span>
            </div>

            <div class="repeater-form">
              <b-row
                v-for="(item, index) in items"
                :key="item.id"
                :id="item.id"
                ref="row"
              >
                <b-form-group class="col pr-0">
                  <validation-provider
                      #default="{ errors }"
                      name="marque"
                      rules="required"
                  >
                    <label for="marque">Marque</label>
                    <combo-box-component
                      id="marque"
                      :dataSource="marquesList"
                      v-model="item.marque"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="numero_chassis"
                    rules="required"
                  >
                    <label for="numero_chassis">Numéro chassis</label>
                    <b-form-input
                      id="numero_chassis"
                      type="text"
                      placeholder="Numéro chassis"
                      v-model="item.numero_chassis"
                      @input="checkChassis(index)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      checkNumeroChassis[index] ? checkNumeroChassis[index] : ""
                    }}</small>

                    <small class="text-danger">{{
                      errors[0] ? errors[0] : item.errorNumero_chassis
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col pr-0">
                  <validation-provider
                      #default="{ errors }"
                      name="couleur"
                      rules="required"
                  >
                    <label for="couleur">Couleur</label>
                    <combo-box-component
                      id="couleur"
                      :dataSource="couleursList"
                      v-model="item.couleur"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="annee"
                    rules="required"
                  >
                    <label for="annee">Année</label>
                    <b-form-input
                      id="annee"
                      type="number"
                      placeholder="Année"
                      v-model="item.annee"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="prix_carte_imp"
                    rules="required"
                  >
                    <label for="prix_carte_imp">Prix carte</label>

                    <cleave
                      id="prix_carte_imp"
                      v-model="item.prix_carte_imp"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      @input="prixRevientItem(item)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Remove Button -->
                <div class="mb-50 col-1 mr-1" v-if="showRemoveItem > 1">
                  <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span></span>
                  </b-button>
                </div>
              </b-row>
            </div>

            <b-button
              size="sm"
              variant="primary"
              :disabled="disabledButton"
              @click="repeateAgain"
              class="my-1"
            >
              <b-icon-plus></b-icon-plus>
              AJOUTER UNE LIGNE
            </b-button>
          </b-card>

          <!-- submit button start -->
          <div class="BlockbButton mt-2">
            <div>
              <b-button
                type="reset"
                class="mr-2 annuler"
                variant="outline-secondary"
                @click="resetInputAll"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                type="submit"
                variant="primary"
                @click.prevent="saveManifeste"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </div>
          </div>
          <!-- submit button end -->
        </b-form>
      </validation-observer>
      <!-- form end -->
    </div>

    <b-card class="mt-5 block-text" style="width: 800px" title="">
      <div class="border-bottom pb-1">
        <span class="h4">SYNTHÈSE DES PRIX</span>
      </div>

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>ELEMENT</b-th>
            <b-th>VALEUR</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>PRIX CARTE IMPORTATEUR</b-td>
            <b-td
              >{{
                totalPrixCarteImp ? $thousandSeparator(totalPrixCarteImp) : 0
              }}
              {{ deviseCarte.abbreviation }}</b-td
            >
          </b-tr>
          <b-tr>
            <b-td>PRIX DE REVIENT</b-td>
            <b-td
              >{{
                calculerPrixRevient
                  ? $thousandSeparator(calculerPrixRevient)
                  : 0
              }}
              {{ deviseCartePrincipale.abbreviation }}</b-td
            >
            <b-td></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BForm,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BIconLayers,
  BIconOption,
  BIconFile,
  BFormDatalist,
  BIconCheckCircleFill,
  BIconPrinterFill,
  BIconArrowClockwise,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BAlert,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";

export default {
  components: {
    VueSweetalert2,

    ComboBoxComponent,
    ComboBoxPlugin,
    Cleave,
    BAlert,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BFormDatalist,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconBuilding,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      disabledButton: false,
      checkNumeroChassis: [],
      marquesList: [],
      couleursList: [],
      importateursList: [],
      societesList: [],
      naviresList: [],
      // deviseCarte_id: 0,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      fieldsCombo: { text: "name", value: "name" },
      deviseCartePrincipale: "",
      deviseCartesList: [],
      devisesManif: [],
      etatButton: true,
      nextTodoId: 2,
      showRemoveItem: 1,
      items: [
        {
          id: 1,
          marque: "",
          numero_chassis: "",
          couleur: "",
          annee: "",
          prix_carte_imp: 10000,
          prix_revient: 0,
          errorNumero_chassis: "",
        },
      ],
      addManifesteForm: {
        importateur: "",
        societe: "",
        navire: "",
        vehicules: [],
        prix_revient: 0,
        devisesManifeste: [],
      },
      errorsImportateur: null,
      errorsSociete: null,
      errorsNavire: null,
      enregistrement_stock_initial: false,
      showManifestStockInitial: false,
    };
  },
  mounted() {
    this.initTrHeight();
    this.getImportateursList();
    this.getSocietesList();
    this.getNaviresList();
    this.getMarquesList();
    this.getCouleursList();
    this.getDeviseCartesList();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
    this.getManifDevises();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },

  computed: {
    deviseCarte() {
      return this.devisesManif.devise_carte_imp
        ? this.devisesManif.devise_carte_imp
        : { value: 0 };
    },

    totalPrixCarteImp() {
      let montant = 0;

      if (this.items.length > 0) {
        this.items.forEach((element) => {
          montant += element.prix_carte_imp
            ? this.$thousandFormater(element.prix_carte_imp)
            : 0;
        });
      }
      return montant;
    },

    calculerPrixRevient() {
      return parseInt(this.totalPrixCarteImp * this.deviseCarte.value);
    },
  },

  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    repeateAgain() {
      this.showRemoveItem = this.showRemoveItem + 1;

      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
        prix_carte_imp: 10000,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },

    removeItem(index) {
      this.showRemoveItem = this.showRemoveItem - 1;
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.addForm.scrollHeight);
      });
    },

    resetInputAll() {
      this.errorsImportateur = null;
      this.errorsSociete = null;
      this.errorsNavire = null;
      this.errorsVehiculeManifeste = [];
    },

    resetInputImportateur() {
      if (this.errorsImportateur) {
        this.errorsImportateur = null;
      }
    },

    resetInputSociete() {
      if (this.errorsSociete) {
        this.errorsSociete = null;
      }
    },

    resetInputNavire() {
      if (this.errorsNavire) {
        this.errorsNavire = null;
      }
    },

    checkIfStockinitial() {
      if (!this.enregistrement_stock_initial) {
        this.$bvModal
          .msgBoxConfirm(
            "En décochant cette option, vous decidez de ne plus enregistrer de stock initial pour cet Importateur . Êtes-vous sûr ?",
            {
              title: "ATTENTION",
              okTitle: "OUI",
              cancelTitle: "NON",
              centered: true,
              hideHeaderClose: false,
              cancelVariant: "outline-secondary",
            }
          )
          .then((value) => {
            if (value === true) {
              // this.defaultForm.createCompte = true;
            } else {
              return (this.enregistrement_stock_initial = true);
            }
          });
      }
    },

    checkAssocie() {
      console.log("itemn")
      if (this.addManifesteForm.importateur) {
        let item = this.importateursList.find(
          (_item) => _item.id === this.addManifesteForm.importateur
        );
        console.log("item")
        console.log(item)
        if(item.hasAlreadyManifeste == false){
          if (item.enregistrement_stock_initial == true) {
            this.showManifestStockInitial = true;
            this.enregistrement_stock_initial = true;
          } else {
            this.showManifestStockInitial = false;
            this.enregistrement_stock_initial = false;
          }
        }else{
          this.showManifestStockInitial = false;
        }
        
      } else {
        this.showManifestStockInitial = false;
        this.enregistrement_stock_initial = false;
      }
    },

    checkChassis(index) {
      if (this.items[index].errorNumero_chassis) {
        this.items[index].errorNumero_chassis = "";
      }

      let enteredChassis = this.items[index].numero_chassis;

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        if (i !== index && item.numero_chassis == enteredChassis) {
          this.checkNumeroChassis[index] =
            "Ce numéro est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButton = true;
          i = this.items.length;
        } else {
          this.disabledButton = false;

          if (this.checkNumeroChassis.length) {
            this.checkNumeroChassis = [];
          }
        }
      }
    },

    formatAmounts(vehicules) {
      vehicules = JSON.parse(JSON.stringify(vehicules));
      vehicules.forEach((element) => {
        element.prix_carte_imp = this.$thousandFormater(element.prix_carte_imp);
      });
      return vehicules;
    },

    saveManifeste() {
      this.$refs.addForm.validate().then((success) => {
        if (!this.checkNumeroChassis.length) {
          let checkError = false;

          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].errorNumero_chassis) {
              checkError = true;
              i = this.items.length;
            }
          }

          if (!checkError) {
            let vehicules = this.formatAmounts(this.items);
            this.addManifesteForm.vehicules = vehicules;
            this.addManifesteForm.devisesManifeste = this.devisesManif;
            this.addManifesteForm.prix_revient = this.calculerPrixRevient;
            this.addManifesteForm.enregistrement_stock_initial =
              this.enregistrement_stock_initial;


            if (success) {
              this.etatButton = false;
              this.$http
                .post("/carte-importateur/manifestes", this.addManifesteForm)
                .then((result) => {
                  if (result.data.success) {
                    this.etatButton = true;
                    this.showToast("Manifeste ajouté avec succès", "success");
                    this.$router.go(-1);
                  } else {
                    this.etatButton = true;
                    this.showToast(result.data.message, "danger");
                  }
                })
                .catch((e) => {
                  this.etatButton = true;
                  var nbrItems = this.items.length;

                  for (var i = 0; i < nbrItems; i++) {
                    if (e.response.data["vehicules." + i + ".numero_chassis"]) {
                      this.items[i].errorNumero_chassis =
                        e.response.data[
                          "vehicules." + i + ".numero_chassis"
                        ][0];
                    }
                  }
                });
            }
          }
        }
      });
    },

    getManifDevises() {
      this.$http.get("/devises-manifeste").then((response) => {
        this.devisesManif = response.data.data[0];
      });
    },

    getImportateursList() {
      this.$http.get("/show-importateurs").then((res) => {
        this.importateursList = res.data.data.reverse();
      });
    },

    getSocietesList() {
      this.$http.get("/societes").then((res) => {
        this.societesList = res.data.data.reverse();

        if (this.societesList.length == 1) {
          this.addManifesteForm.societe = this.societesList[0].id;
        }
      });
    },

    getNaviresList() {
      this.$http.get("/navires").then((res) => {
        this.naviresList = res.data.data.reverse();

        this.naviresList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getMarquesList() {
      this.$http.get("/marques").then((res) => {
        this.marquesList = res.data.data.reverse();

        this.marquesList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getCouleursList() {
      this.$http.get("/couleurs").then((res) => {
        this.couleursList = res.data.data.reverse();

        this.couleursList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getDeviseCartesList() {
      this.$http.get("/devises").then((res) => {
        this.deviseCartesList = res.data.data;
        if (this.deviseCartesList.length) {
          this.deviseCartePrincipale = this.deviseCartesList.find(
            ({ id }) => id === 1
          );
        }
      });
    },

    prixRevientItem(item) {
      return (item.prix_revient = parseInt(
        item.prix_carte_imp * this.deviseCarte.value
      ));
    },

    changeDeviseCarte() {
      this.devisesManif.devise_carte_imp = this.deviseCartesList.find(
        ({ id }) => id === this.devisesManif.dev_carte_imp
      );
    },
  },
};
</script>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
.e-input-group {
  border-color: rgb(0 0 0 / 15%) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  color: rgb(127 125 139) !important;
  line-height: 2.1 !important;
  padding-left: 15px !important;
}

.e-input-group::before,
.e-input-group::after {
  background: #e3165b00 !important;
}
.block-text .card-body {
  padding-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>